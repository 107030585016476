import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import SafetyEquipment from '../SafetyEquipment';
import { updateUser } from '../../actions';
import { Button, Input, Card } from '..';
import NumberFormat from 'react-number-format';

const EditButton = styled(Button)`
  border-radius: 4px;
  padding: 6px 10px;
  flex-grow: 0;
  min-width: 50px;
  height: 29px;
  font-family: 'Hauora';
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  text-align: center;
  transition: box-shadow 0.2s;
  min-width: 0;
  width: auto;
  margin: 0;
`;
const SaveButton = styled(EditButton)`
  background: var(--key-color);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: none;
  color: white;
  text-align: center;
  transition: box-shadow 0.2s;
  margin-left: 10px;
`;
const DetailsHeader = styled.div`
  padding: 15px 0px 15px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  width:100%;
  @media (max-width:768px) {
    padding-top:0;
      }
`;

const DetailsContainer = styled(Card)`
display: inline-block;
max-height:72vh;
overflow-y:auto;
flex-direction: column;
justify-content: center;
width: 528px;
padding: 0px 50px;
  @media (max-width:900px) {
    max-height:100%;
  }
  @media (max-width:991px) {
    display:block;
    width:100%;
    padding:13px;
  }
`;
const ChildContainer = styled.div`
width:100%;
h5{
  font-weight:600;
  color: var(--light-text-color);
  font-size:15px;
  @media (max-width:689px) {
      font-size:12px;
  }
}
`

class Safety extends React.Component {
  state = {
    safetyEquipment: this.props.user.safetyEquipments.map((se) => se._id) || [],
    localEmergencyContactName: this.props.user.localEmergencyContactName,
    localEmergencyContactPhone: this.props.user.localEmergencyContactPhone,
    ootEmergencyContactName: this.props.user.ootEmergencyContactName,
    ootEmergencyContactPhone: this.props.user.ootEmergencyContactPhone,
    hospitalPref: this.props.user.hospitalPref,
    familyPhysician: this.props.user.familyPhysician,
    pharmacyName: this.props.user.pharmacyName,
    religiousOrg: this.props.user.religiousOrg,
    allergies: this.props.user.allergies,
    editing: true,
    updated: true
  };
  handleSave = (e) => {
    const {
      safetyEquipment,
      localEmergencyContactName,
      localEmergencyContactPhone,
      ootEmergencyContactName,
      ootEmergencyContactPhone,
      hospitalPref,
      familyPhysician,
      pharmacyName,
      religiousOrg,
      allergies,
      editing,
      updated
    } = this.state;

    const { dispatch } = this.props;
    e.preventDefault();
    this.setState({ editing: true });
    dispatch(
      updateUser({
        safetyEquipments: safetyEquipment,
        localEmergencyContactName,
        localEmergencyContactPhone,
        ootEmergencyContactName,
        ootEmergencyContactPhone,
        hospitalPref,
        familyPhysician,
        pharmacyName,
        religiousOrg,
        allergies,
        updated
      })
    );
  };
  handleSafetyEquipmentChange = ({ target: { checked, name } }) => {
    let safetyEquipment = this.state.safetyEquipment;
    const { equipments } = this.props;
    if (checked) {
      if (name === 'allEquipments') {
        equipments.map((eq) => {
          if (!safetyEquipment.includes(eq._id))
            safetyEquipment.push(eq._id);
        });
      } else {
        safetyEquipment.push(name);
      }
    } else {
      if (name === 'allEquipments') {
        safetyEquipment = [];
      } else {
        safetyEquipment = safetyEquipment.filter((se) => se !== name);
      }
    }
    this.setState({ safetyEquipment });
  };
  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };
  render() {
    const {
      safetyEquipment,
      localEmergencyContactName,
      localEmergencyContactPhone,
      ootEmergencyContactName,
      ootEmergencyContactPhone,
      hospitalPref,
      familyPhysician,
      pharmacyName,
      religiousOrg,
      allergies,
      editing,
      updated
    } = this.state;
    return (
      <DetailsContainer>
        <DetailsHeader>
          <h2>Safety</h2>
          <div>
            <EditButton disabled={!editing}
              onClick={() => this.setState({ editing: false })} >Edit</EditButton>
            <SaveButton onClick={this.handleSave} disabled={editing}>Save</SaveButton>
          </div>
        </DetailsHeader>
        <ChildContainer>
          <SafetyEquipment
            onChange={this.handleSafetyEquipmentChange}
            selected={safetyEquipment}
            editing={editing}
          />
          {/* <h5>Local personal emergency Contact</h5>
          <Input
            type='text'
            onChange={this.handleChange}
            name='localEmergencyContactName'
            value={localEmergencyContactName}
            label='Name'
            placeholder='Contact Name'
            disable={editing}
          />
          <Input
            type='text'
            onChange={this.handleChange}
            name='localEmergencyContactPhone'
            value={localEmergencyContactPhone}
            label='Phone Number'
            placeholder='Phone Number'
            disable={editing}
          />
          <h5>Out-of-town emergency Contact</h5>
          <Input
            type='text'
            onChange={this.handleChange}
            name='ootEmergencyContactName'
            value={ootEmergencyContactName}
            label='Name'
            placeholder='Contact Name'
            disable={editing}
          />
          <NumberFormat
            type='text'
            onChange={this.handleChange}
            name='ootEmergencyContactPhone'
            value={ootEmergencyContactPhone}
            label='Phone Number'
            placeholder='Phone Number'
            format=''
            customInput={Input}
            disable={editing}
          />
          <Input
            type='text'
            onChange={this.handleChange}
            name='hospitalPref'
            value={hospitalPref}
            label='Hospital Preference'
            placeholder='Hospital Name'
            disable={editing}
          />
          <Input
            type='text'
            onChange={this.handleChange}
            name='familyPhysician'
            value={familyPhysician}
            label='Family Physician'
            placeholder='Physician Name'
            disable={editing}
          />
          <Input
            type='text'
            onChange={this.handleChange}
            name='pharmacyName'
            value={pharmacyName}
            label='Pharmacy'
            placeholder='Pharmacy Name'
            disable={editing}
          />
          <Input
            type='text'
            onChange={this.handleChange}
            name='religiousOrg'
            value={religiousOrg}
            label='Religious Organization'
            placeholder='None'
            disable={editing}
          />
          <Input
            type='text'
            onChange={this.handleChange}
            name='allergies'
            value={allergies}
            label='Allergies'
            placeholder='None'
            disable={editing}
          /> */}
        </ChildContainer>
      </DetailsContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
    equipments: state.equipment.equipment || []
  };
};

export default connect(mapStateToProps)(Safety);
