import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { Alert, ButtonGroup, Col, Container, Modal, Row, Spinner, ToggleButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';

import { Checkbox, Input, Button, TransparentButton } from '../../components';

import infoIcon from '../../assets/images/info.svg';
import trash from '../../assets/images/trash.svg';

import { StyledLabel, ToggleButtonContainer, WhiteButton } from '../../pages/Administration/Style';
import { DropDownStyle } from '../../helpers';
import { loadUser } from '../../actions';

const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry{
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding: 7px;
    }
    input{
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        padding: 9px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
        width:100%;
        margin-top:2px;
        margin-bottom:2px;
    }
`;

class AddEditStaffModal extends Component {
    state = {
        locationLoading: false,
        countryCode: '1',
        orderBy: 'nameForSort',
        order: 'asc',
        pageSize: 10,
        pageNumber: 1,
        pageCount: 0,
        totalCount: 0,
        addOrEditUser: false,
        locations: [],
        allLocationForPractice: [],
        roles: [],
        errors: {
            maxLocations: false,
            getUsersErr: false
        },
        userLoading: false,
        saveOrUpdateInProgress: false,
        elementRef: createRef(),
        isDeleteUserModalOpen: false,
        locationDetailModalOpen: false,
        safetyEquipmentDetailModalOpen: false,
        activeFilterDetails: {},
        selectedLocations: [this.props.user.location._id],
        userDetails: { ...this.props.staffDetails },
    }

    componentDidMount() {
        this.getMasterData();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.staffDetails?._id !== this.props.staffDetails?._id) {
            console.log("User prop updated:", this.props.staffDetails);
            this.setState({ userDetails: { ...this.props.staffDetails }, selectedLocations: [this.props.staffDetails.location._id] });
        }
    }

    getUserDetails = async () => {
        const { staffDetails } = this.props
        if (staffDetails) {
            const userData = await this.getUser(staffDetails._id);
            userData.spiceOfficerLocations = userData.spiceOfficerLocations || [];
            this.setState({
                userDetails: userData,
                countryCode: userData.countryCode,
                userLoading: false
            });
        } else {
            this.setState({ userLoading: false });
        }
    };

    getMasterData = async () => {
        try {
            this.setState({ userLoading: true });
            const { user } = this.props;
            const { data: { payload: locations } } = await axios.get(`/api/v2/master/locations_by_user/${user._id}`);
            const { data: { payload: roles } } = await axios.get(`/api/v2/master/roles/${user._id}`);
            this.setState({ locations, roles });
            await this.getUserDetails();
        } catch (error) {
            this.setState({ userLoading: false });
            console.error(error);
        }
    };

    onCheckBoxChange = () => {
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                newHire: !this.state.userDetails.newHire
            }
        });
    };

    addNewLocation = () => {
        const { userDetails, locations, errors, elementRef } = this.state;
        const { user: { adminLocations } } = this.props;
        const { err, locationError } = this.validate();
        if (!locationError && !err) {
            if (locations.length === userDetails.locations.filter((loc) => adminLocations.includes(loc) || loc === ' ').length) {
                this.setState({ errors: { maxLocations: true } });
            } else {
                userDetails.locations.push(' ');
                this.setState({ userDetails });
            };
            setTimeout(() => {
                if (elementRef.current) {
                    elementRef.current.scrollIntoView({ behavior: "smooth" });
                };
            }, 0);
        } else {
            setTimeout(() => {
                const element = document.getElementById('error-alert');
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                };
            }, 0);
        };
    };

    handleStaffModelChange = (name, value, location) => {
        let { userDetails, locations } = this.state;
        if (name === 'location') {
            userDetails = this.onLocationChangeUpdatePositions(userDetails, location);
            userDetails = this.onLocationChangeUpdateRoles(userDetails, location);
            const locIndex = userDetails.locations.indexOf(location);
            if (locIndex >= 0) userDetails.locations.splice(locIndex, 1);
            userDetails.locations.push(value.value);
        };

        if (name === 'position') {
            if (value.checked) {
                if (value.value === 'administration') {
                    if (this.checkDisabled(location)) return;
                    locations = locations.map((lo) => {
                        if (lo.value === location) {
                            lo.admin += 1;
                        };
                        return lo;
                    });
                    userDetails.newHire = false;
                    userDetails.adminLocations.push(location);
                };
                if (value.value === 'atRisk') {
                    const notAtRiskLocationIndex = userDetails.notAtRiskLocations.indexOf(location);
                    if (notAtRiskLocationIndex >= 0) userDetails.notAtRiskLocations.splice(notAtRiskLocationIndex, 1);
                    userDetails.atRiskLocations.push(location);
                };
                if (value.value === 'notAtRisk') {
                    const atRiskLocationIndex = userDetails.atRiskLocations.indexOf(location);
                    if (atRiskLocationIndex >= 0) userDetails.atRiskLocations.splice(atRiskLocationIndex, 1);
                    userDetails.notAtRiskLocations.push(location);
                };
                if (value.value === 'safetyOfficer') {
                    userDetails.safetyOfficerLocations.push(location);
                };
                if (value.value === 'spiceOfficer') {
                    userDetails.spiceOfficerLocations.push(location);
                };
            } else {
                if (value.value === 'administration') {
                    if (this.checkDisabled(location)) return;
                    const adminLocationIndex = userDetails.adminLocations.indexOf(location);
                    if (adminLocationIndex >= 0) {
                        userDetails.adminLocations.splice(adminLocationIndex, 1);
                        locations = locations.map((lo) => {
                            if (lo.value === location) {
                                lo.admin -= 1;
                            };
                            return lo;
                        });
                    };
                };
                if (value.value === 'safetyOfficer') {
                    const safetyOfficerLocationIndex = userDetails.safetyOfficerLocations.indexOf(location);
                    if (safetyOfficerLocationIndex >= 0) userDetails.safetyOfficerLocations.splice(safetyOfficerLocationIndex, 1);
                };
                if (value.value === 'spiceOfficer') {
                    const spiceOfficerLocationIndex = userDetails.spiceOfficerLocations.indexOf(location);
                    if (spiceOfficerLocationIndex >= 0) userDetails.spiceOfficerLocations.splice(spiceOfficerLocationIndex, 1);
                };
            }
        };

        if (name === 'role') {
            userDetails = this.onLocationChangeUpdateRoles(userDetails, location);
            const eq = [...userDetails.safetyEquipments, ...value.defaultEquipment]
            userDetails.safetyEquipments = [...new Set(eq)];
            userDetails.roles.push(value.value);
        };
        this.setState({ userDetails, locations });
    };

    onLocationChangeUpdatePositions = (userDetails, location) => {
        let { locations } = this.state;
        userDetails.locations.filter((loc) => loc);
        if (location) {
            const adminLocationIndex = userDetails.adminLocations.indexOf(location);
            if (adminLocationIndex >= 0) {
                locations = locations.map((lo) => {
                    if (lo.value === location) {
                        lo.admin -= 1;
                    };
                    return lo;
                });
                this.setState({ locations });
                userDetails.adminLocations.splice(adminLocationIndex, 1);
            };

            const atRiskLocationIndex = userDetails.atRiskLocations.indexOf(location);
            if (atRiskLocationIndex >= 0) userDetails.atRiskLocations.splice(atRiskLocationIndex, 1);

            const notAtRiskLocationIndex = userDetails.notAtRiskLocations.indexOf(location);
            if (notAtRiskLocationIndex >= 0) userDetails.notAtRiskLocations.splice(notAtRiskLocationIndex, 1);

            const safetyOfficerLocationIndex = userDetails.safetyOfficerLocations.indexOf(location);
            if (safetyOfficerLocationIndex >= 0) userDetails.safetyOfficerLocations.splice(safetyOfficerLocationIndex, 1);

            const spiceOfficerLocationIndex = userDetails.spiceOfficerLocations.indexOf(location);
            if (spiceOfficerLocationIndex >= 0) userDetails.spiceOfficerLocations.splice(spiceOfficerLocationIndex, 1);
        };
        return userDetails;
    };

    onLocationChangeUpdateRoles = (userDetails, location) => {
        if (location) {
            const { roles } = this.state;

            const { roles: rolesForLoc } = roles.find((role) => role._id === location) || {};
            const locRoles = rolesForLoc ? rolesForLoc.map((role) => role.value) || [] : [];
            const tempRole = userDetails.roles ? userDetails.roles.find((role) => locRoles.includes(role)) : '';
            const prevRole = rolesForLoc && rolesForLoc.find((role) => role.value === tempRole);
            if (prevRole && prevRole.defaultEquipment && prevRole.defaultEquipment.length) {
                prevRole.defaultEquipment.forEach((item) => {
                    const eqIndex = userDetails.safetyEquipments.indexOf(item);
                    if (eqIndex >= 0) userDetails.safetyEquipments.splice(eqIndex, 1);
                });
            };
            const roleIndex = userDetails.roles?.indexOf(tempRole);
            if (roleIndex >= 0) userDetails.roles?.splice(roleIndex, 1);
        };
        return userDetails;
    };

    findDefaultRoles = (location) => {
        if (location) {
            const { roles, userDetails } = this.state;
            const roleIds = roles.find((role) => role._id === location)?.roles || [];
            return roleIds.find((role) => userDetails.roles?.includes(role.value));
        } else {
            return '';
        }
    };

    deleteLocation = (location, index) => {
        let { userDetails, errors } = this.state;
        userDetails.locations.splice(index, 1);
        userDetails = this.onLocationChangeUpdatePositions(userDetails, location);
        userDetails = this.onLocationChangeUpdateRoles(userDetails, location);
        delete errors[index];
        errors.maxLocations = false;
        this.setState({ errors, userDetails });
    };

    validate = () => {
        let err = false;
        let locationError = false;
        let tempErrors = {};
        const { userDetails, userDetails: { locations: userLocations, adminLocations, atRiskLocations, notAtRiskLocations, safetyOfficerLocations, spiceOfficerLocations, firstName, lastName, email, phone, practiceAdmin }, roles } = this.state;

        if (!firstName) {
            tempErrors.firstName = true;
            err = true;
        };
        if (!lastName) {
            tempErrors.lastName = true;
            err = true;
        };
        if (!email && !phone) {
            tempErrors.invalid = true;
            tempErrors.msg = 'Email or Phone must be needed.';
            err = true;
        };
        if (email && !email.match(/[^@]+@[^@]+\.[a-zA-Z]{2,6}/)) {
            tempErrors.invalid = true;
            tempErrors.msg = 'Invalid Email address.';
            err = true;
        };
        if (phone && phone.length > 3 && !isValidPhoneNumber(phone)) {
            tempErrors.invalid = true;
            tempErrors.msg = 'Invalid Phone number.';
            err = true;
        };
        if (practiceAdmin && !email) {
            tempErrors.invalid = true;
            tempErrors.msg = 'As a practice admin, email is a required.';
            err = true;
        };
        const { user: { adminLocations: admin_locations } } = this.props;
        if (userDetails)
            userLocations.forEach((location, index) => {
                if (admin_locations.includes(location) || location === ' ') {
                    if (location) {
                        tempErrors[index] = {};
                        const roleIds = roles.find((role) => role._id === location)?.roles.map((role) => role.value) || [];
                        const selectedRole = userDetails.roles.find((role) => roleIds.includes(role));
                        if (!selectedRole) {
                            locationError = true;
                            tempErrors[index].role = true;
                        };
                        if (!adminLocations.includes(location) && !safetyOfficerLocations.includes(location) && !spiceOfficerLocations.includes(location) && !atRiskLocations.includes(location) && !notAtRiskLocations.includes(location)) {
                            locationError = true;
                            tempErrors[index].position = true;
                        };

                    } else {
                        locationError = true;
                        tempErrors[index] = { location: true };
                    };
                };
            });
        this.setState({ errors: tempErrors });
        return { err, locationError };
    };

    getDefaultEquipmentAndAddOrRemove = () => {
        const { userDetails, roles } = this.state;
        let defaultEquipment = userDetails.safetyEquipments || [];
        userDetails.locations.forEach(loc => {
            const role = roles.find((ro) => ro._id === loc);
            if (role) {
                role.roles.forEach(ro => {
                    if (userDetails.roles.includes(ro.value)) {
                        defaultEquipment = [...defaultEquipment, ...ro.defaultEquipment];
                    };
                });
            };
        });
        userDetails.safetyEquipments = [...new Set(defaultEquipment)];
        return userDetails;
    };

    saveOrUpdate = async () => {
        try {
            const { err, locationError } = this.validate();
            if (!err && !locationError) {
                const { userDetails: { _id }, countryCode } = this.state;
                try {
                    this.setState({ saveOrUpdateInProgress: true });
                    const { user: { practice } } = this.props;
                    const newUser = this.getDefaultEquipmentAndAddOrRemove();
                    newUser.newHire = newUser.adminLocations.length ? false : newUser.newHire;
                    newUser.practices = [practice._id];
                    newUser.countryCode = countryCode;
                    if (_id) {
                        await axios.put('/api/v2/user/' + _id, newUser);
                        const { user, dispatch } = this.props;
                        if (newUser._id === user._id) {
                            await dispatch(loadUser(user));
                        };
                        Swal.fire({
                            icon: 'success',
                            title: 'User has been updated successfully',
                            allowEnterKey: true,
                            allowEscapeKey: false,
                            confirmButtonColor: '#4FB973',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false
                        });
                    } else {
                        await axios.post('/api/v2/user', newUser);
                        Swal.fire({
                            icon: 'success',
                            title: 'User has been added successfully',
                            allowEnterKey: true,
                            allowEscapeKey: false,
                            confirmButtonColor: '#4FB973',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false
                        });
                    }
                    this.closePopUpModal();
                } catch (error) {
                    if (error.response && error.response.status === 409) {
                        this.setState({
                            saveOrUpdateInProgress: false,
                            errors: { apiError: true, errorMessage: error.response.data.message }
                        });
                        return;
                    };
                    this.setState({
                        saveOrUpdateInProgress: false,
                        errors: { apiError: true, errorMessage: !_id ? 'Error at adding user.' : 'Error at updating user.' }
                    });
                    return;
                }
            };
        } catch (error) {
            console.error(error);
        }
    };

    closePopUpModal = () => {
        this.setState({
            addOrEditUser: false,
            userDetails: {
                firstName: "", lastName: "", email: "", phone: "", locations: [' '], adminLocations: [], atRiskLocations: [], notAtRiskLocations: [], safetyOfficerLocations: [], roles: [], safetyEquipments: [], spiceOfficerLocations: []
            },
            countryCode: '1',
            errors: {
                maxLocations: false,
            },
            saveOrUpdateInProgress: false,
            isDeleteUserModalOpen: false,
            locationDetailModalOpen: false,
            safetyEquipmentDetailModalOpen: false
        });
        this.props.closePopup()
    };

    getUser = async (userId) => {
        try {
            const { data: { payload } } = await axios.get(`/api/v2/user/${userId}`);
            payload.notAtRiskLocations = payload.locations.filter(lo => !payload.atRiskLocations.includes(lo));
            return payload;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    editAndAddUser = async (user) => {
        console.log('user=> editAndAddUser', user);
        this.setState({ addOrEditUser: true, userLoading: true });
        await this.getMasterData();
        if (user) {
            const userData = await this.getUser(user._id);
            userData.spiceOfficerLocations = userData.spiceOfficerLocations || [];
            this.setState({
                userDetails: userData,
                countryCode: userData.countryCode,
                userLoading: false
            });
        } else {
            this.setState({ userLoading: false });
        }
    };

    checkDisabled = (location) => {
        const { locations, userDetails } = this.state;
        let disabled = false;
        if (userDetails?.isRegistrationCompleted == 2 && userDetails?._id && location !== ' ' && userDetails?.adminLocations.includes(location) && locations.find((loc) => loc.value === location)?.admin <= 1) {
            disabled = true;
        };
        return disabled;
    };

    onModelChange = (name, value) => {
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                [name]: value
            }
        });
    };

    countryChange = (e) => {
        if (!e) {
            console.warn("Country code is undefined, using default value '1'.");
        }
        const countryCode = e ? getCountryCallingCode(e) : '1';
        this.setState({ countryCode });
    };

    render() {
        const { userLoading, locationLoading, userDetails, locations, roles,
            errors: { maxLocations, firstName, lastName, email, phone, invalid, apiError, errorMessage }, errors, elementRef, saveOrUpdateInProgress } = this.state;

        const { user: { adminLocations } } = this.props;

        return (
            <>
                {
                    <Modal
                        centered
                        animation={true}
                        backdrop="static"
                        keyboard={false}
                        size='lg'
                        show={this.props.showModal}>
                        {userLoading || locationLoading ?
                            <Container className='text-center my-5'>
                                <Spinner animation="border" variant="success" />
                            </Container> :
                            <>
                                <Modal.Header >
                                    <Modal.Title>{userDetails._id ? 'Edit' : 'Add'} Staff</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                        <h5 className='mb-0'>
                                            Personal Information
                                            <hr className='mt-0' />
                                        </h5>
                                        <Row>
                                            <Col>
                                                <Input required={true} onChange={(e) => this.onModelChange('firstName', e.target.value)} label='First Name' type='text' placeholder='First Name' value={userDetails.firstName} />
                                            </Col>
                                            <Col>
                                                <Input required={true} onChange={(e) => this.onModelChange('lastName', e.target.value)} label='Last Name' type='text' placeholder='Last Name' value={userDetails.lastName} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Input onChange={(e) => this.onModelChange('email', e.target.value)} label='Email Address' type='text' placeholder='Email Address' value={userDetails.email} />
                                            </Col>
                                            <Col>
                                                <StyledLabel>Phone Number</StyledLabel>
                                                <CustomPhoneInput
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry={'US'}
                                                    smartCaret={true}
                                                    countryCallingCodeEditable={false}
                                                    international={true}
                                                    autoComplete='off'
                                                    placeholder="Enter phone number"
                                                    onChange={(e) => this.onModelChange('phone', e)}
                                                    onCountryChange={((e) => this.countryChange(e))}
                                                    value={userDetails.phone}
                                                />
                                            </Col>
                                            <Col lg={12}>
                                                <h5 className='text-success my-3'> <img src={infoIcon} className='mr-2' />Either an email or phone number is required</h5>
                                            </Col>
                                        </Row>
                                        {(firstName || lastName || email || phone || invalid) &&
                                            <Row>
                                                <Col>
                                                    <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                                        <p className='w-100 mb-0'>
                                                            {!invalid ? 'Fill all required fields.' : errors.msg}
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        }
                                        {(!userDetails.adminLocations?.length) &&
                                            <Row>
                                                <Col md='12' sm='12' lg='12'>
                                                    <Checkbox onChange={this.onCheckBoxChange} checked={userDetails.newHire} />New Hire
                                                    <br />
                                                </Col>
                                                <Col md='12' sm='12' lg='12'>
                                                    Designating this employee as a new hire will require them to complete all OSHA training within 10 days.
                                                </Col>
                                            </Row>
                                        }
                                        <h5 className='mt-3 mb-0'>
                                            Staff Details
                                            <hr className='mt-0 mb-1' />
                                        </h5>
                                        {userDetails.locations.map((location, index) => (
                                            (adminLocations.includes(location) || location === ' ') &&
                                            <div if={'error-block' + index} key={location + index}>
                                                {index > 0 &&
                                                    <hr className='my-2' />
                                                }
                                                <Row>
                                                    <Col xs='12' md='12' lg="6">
                                                        <StyledLabel>Practice Locations <span className="text-danger">*</span></StyledLabel>
                                                        <Select
                                                            isDisabled={this.checkDisabled(location)} defaultValue={locations.find((loc) => location === loc.value)} onChange={(e) => this.handleStaffModelChange('location', e, location)} options={locations.filter((loc) => !userDetails.locations.includes(loc.value) || loc.value === location)} placeholder='Location'
                                                            required={true}
                                                            menuPosition="fixed"
                                                            styles={DropDownStyle}
                                                        />
                                                    </Col>
                                                    <Col xs='12' md='12' lg="6">
                                                        <div className="d-flex justify-content-between">
                                                            <StyledLabel>Role<span className="text-danger">*</span></StyledLabel>
                                                            <TransparentButton
                                                                disabled={this.checkDisabled(location) || userDetails.locations.length <= 1} className='mr-2' onClick={() => this.deleteLocation(location, index)} type='button'>
                                                                <img src={trash} alt="Delete location" />
                                                            </TransparentButton>
                                                        </div>
                                                        <Select required={true} menuPosition="fixed"
                                                            styles={DropDownStyle} defaultValue={this.findDefaultRoles(location)} label='Role' onChange={(e) => this.handleStaffModelChange('role', e, location)} options={roles.find((role) => role._id === location)?.roles || []} placeholder='Role' />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' md='8' lg="6">
                                                        <StyledLabel>Designation</StyledLabel>
                                                        <ToggleButtonContainer>
                                                            <ButtonGroup toggle>
                                                                <ToggleButton
                                                                    type="checkbox"
                                                                    variant="outline-success"
                                                                    name='administration'
                                                                    value='administration'
                                                                    checked={userDetails.adminLocations && userDetails.adminLocations.includes(location)}
                                                                    onChange={(e) => this.handleStaffModelChange('position', { checked: e.currentTarget.checked, value: e.currentTarget.value }, location)}
                                                                >

                                                                    Administrator
                                                                </ToggleButton>
                                                            </ButtonGroup>
                                                            <ButtonGroup toggle className='ml-2'>
                                                                <ToggleButton
                                                                    type="checkbox"
                                                                    variant="outline-success"
                                                                    name='safetyOfficer'
                                                                    value='safetyOfficer'
                                                                    checked={userDetails.safetyOfficerLocations && userDetails.safetyOfficerLocations.includes(location)}
                                                                    onChange={(e) => this.handleStaffModelChange('position', { checked: e.currentTarget.checked, value: e.currentTarget.value }, location
                                                                    )}
                                                                >
                                                                    Safety Officer
                                                                </ToggleButton>
                                                            </ButtonGroup>
                                                            <ButtonGroup toggle className='ml-2'>
                                                                <ToggleButton
                                                                    type="checkbox"
                                                                    variant="outline-success"
                                                                    name='spiceOfficer'
                                                                    value='spiceOfficer'
                                                                    checked={userDetails.spiceOfficerLocations && userDetails.spiceOfficerLocations.includes(location)}
                                                                    onChange={(e) => this.handleStaffModelChange('position', { checked: e.currentTarget.checked, value: e.currentTarget.value }, location)}
                                                                >
                                                                    Spice Officer
                                                                </ToggleButton>
                                                            </ButtonGroup>
                                                        </ToggleButtonContainer>
                                                    </Col>
                                                    <Col xs='12' md='4' lg="4">
                                                        <StyledLabel>Exposure <span className="text-danger">*</span></StyledLabel>
                                                        <ToggleButtonContainer className='d-flex'>
                                                            <ButtonGroup toggle className='mr-3'>
                                                                <ToggleButton
                                                                    type="checkbox"
                                                                    variant="outline-success"
                                                                    name='atRisk'
                                                                    value='atRisk'
                                                                    checked={userDetails.atRiskLocations && userDetails.atRiskLocations.includes(location)}
                                                                    onChange={(e) => this.handleStaffModelChange('position', { checked: e.currentTarget.checked, value: e.currentTarget.value }, location
                                                                    )}
                                                                >
                                                                    At Risk
                                                                </ToggleButton>
                                                            </ButtonGroup>
                                                            <ButtonGroup toggle>
                                                                <ToggleButton
                                                                    type="checkbox"
                                                                    variant="outline-success"
                                                                    name='notAtRisk'
                                                                    value='notAtRisk'
                                                                    checked={userDetails.notAtRiskLocations && userDetails.notAtRiskLocations.includes(location)}
                                                                    onChange={(e) => this.handleStaffModelChange('position', { checked: e.currentTarget.checked, value: e.currentTarget.value }, location)}
                                                                >
                                                                    Not At Risk
                                                                </ToggleButton>
                                                            </ButtonGroup>
                                                        </ToggleButtonContainer>
                                                    </Col>
                                                </Row>
                                                {
                                                    errors[index] && (errors[index].location || errors[index].role || errors[index].position) &&
                                                    <Row>
                                                        <Col>
                                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                                                <p className='w-100 mb-0'>Fill all required fields.</p>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    userDetails.isRegistrationCompleted == '2' && this.checkDisabled(location) &&
                                                    <Row>
                                                        <Col>
                                                            <Alert className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="warning">
                                                                <p className='w-100 mb-0'>{userDetails.firstName ? userDetails.firstName : 'This user'} being the only admin, won't be able to change the location and position.</p>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        ))}
                                        <small ref={elementRef} id='elementRef' />
                                    </Container>
                                    <Container>
                                        {maxLocations &&
                                            <Row>
                                                <Col>
                                                    <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                                        onClose={() => this.setState({ errors: { ...errors, maxLocations: false } })} dismissible>
                                                        <p className='w-100 mb-0'>You are the admin for these locations only. You cannot add any more locations.</p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        }
                                        {apiError &&
                                            <Row>
                                                <Col>
                                                    <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                                        onClose={() => this.setState({ errors: { ...errors, apiError: false } })} dismissible>
                                                        <p className='w-100 mb-0'>{errorMessage}</p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <WhiteButton className='my-3' type='button' onClick={() => this.addNewLocation()}>+ Add Location</WhiteButton>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <WhiteButton className='mt-0' type='button' onClick={() => this.closePopUpModal()}>Cancel</WhiteButton>
                                            </Col>
                                            <Col>
                                                <Button disabled={saveOrUpdateInProgress} className='mt-0' type='button' onClick={() => this.saveOrUpdate()}>{userDetails._id ? (saveOrUpdateInProgress ? 'Updating...' : 'Update') : (saveOrUpdateInProgress ? 'Saving...' : 'Save')}</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                            </>
                        }
                    </Modal>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

export default connect(mapStateToProps)(AddEditStaffModal);