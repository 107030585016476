
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { ColHeader, TaskBadge, TaskBody, TaskContainer, TaskHeader, TaskInstruction, TaskListContainer, TaskTitle, TasksCard, TasksColumn, TaskRowBody, TaskHead, TaskText, NumberCircle, NameBlocks } from "./Style"
import { SearchInput } from "../Administration/Style";
import { HeaderContainer } from '../../components'
import clock from '../../assets/images/clock.svg';
import warning from '../../assets/images/warning.svg';
import moment from "moment";
import { Spinner, Col } from "react-bootstrap";
import TaskDetails from "./TaskDetails";

const TaskDashboard = () => {

    const currentUser = useSelector(state => state.user.currentUser);

    const [loading, setLoading] = useState(false);
    const [tasksList, setTaskList] = useState({ dueOnce: [], dueThisWeek: [], dueThisMonth: [], quarterly: [], dueThisYear: [] });
    const [taskDetailsOpen, setTaskDetailOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState({});
    const [forms, setForms] = useState({});
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [originalTasksList, setOriginalTasksList] = useState({
        dueToday: tasksList.dueToday || [],
        dueOnce: tasksList.dueOnce || [],
        dueThisWeek: tasksList.dueThisWeek || [],
        dueThisMonth: tasksList.dueThisMonth || [],
        quarterly: tasksList.quarterly || [],
        dueThisYear: tasksList.dueThisYear || []
    });

    useEffect(() => {
        getTasks();
        getMasterData();
        getFormsList();
        setOriginalTasksList({
            dueToday: tasksList.dueToday || [],
            dueOnce: tasksList.dueOnce || [],
            dueThisWeek: tasksList.dueThisWeek || [],
            dueThisMonth: tasksList.dueThisMonth || [],
            quarterly: tasksList.quarterly || [],
            dueThisYear: tasksList.dueThisYear || []
        });
    }, []);

    const getMasterData = async () => {
        try {
            let { data: { payload } } = await axios.get(`/api/v2/master/users_by_location/${currentUser.location._id}`);
            setUsers(payload);
        } catch (error) {
            console.log("🚀 ~ getMasterData ~ error:", error)
        }
    };

    const handleSearch = (value) => {
        setSearch(value);
        const lowercasedValue = value.toLowerCase().trim();
        if (!lowercasedValue) {
            setTaskList(originalTasksList);
            return;
        }
        const filteredTasks = {
            dueToday: originalTasksList.dueToday.filter(task =>
                task.name.toLowerCase().includes(lowercasedValue)
            ),
            dueOnce: originalTasksList.dueOnce.filter(task =>
                task.name.toLowerCase().includes(lowercasedValue)
            ),
            dueThisWeek: originalTasksList.dueThisWeek.filter(task =>
                task.name.toLowerCase().includes(lowercasedValue)
            ),
            dueThisMonth: originalTasksList.dueThisMonth.filter(task =>
                task.name.toLowerCase().includes(lowercasedValue)
            ),
            quarterly: originalTasksList.quarterly.filter(task =>
                task.name.toLowerCase().includes(lowercasedValue)
            ),
            dueThisYear: originalTasksList.dueThisYear.filter(task =>
                task.name.toLowerCase().includes(lowercasedValue)
            )
        };
        setTaskList(filteredTasks);
    };

    const getTasks = async () => {
        try {
            setLoading(true);
            let { data: { payload } } = await axios.get(`/api/v2/users_tasks/${currentUser._id}/${currentUser.location._id}`);

            if (!currentUser.isAdmin) {
                payload = payload.filter(task => !task.roles.includes('admin'));
            };

            const tasksObj = {};

            tasksObj.dueToday = [];

            tasksObj.dueOnce = payload.filter(t => t.frequency === '99' && !tasksObj.dueToday.find((ta) => ta._id === t._id) && !t.lastCompletedAt);

            tasksObj.dueThisWeek = payload.filter(task => moment(task.due_date).isSame(moment(), 'week') && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== '99' && task.frequency === '0');

            tasksObj.dueThisMonth = payload.filter(task => moment(task.due_date).isSame(moment(), 'month') && !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== '99' && task.frequency === '1');
            const startOfCurrentQuarter = moment().startOf('quarter');
            const endOfCurrentQuarter = moment().endOf('quarter');
            tasksObj.quarterly = payload.filter(task => moment(task.due_date).isBetween(startOfCurrentQuarter, endOfCurrentQuarter) && !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== '99' && task.frequency === '2');

            tasksObj.dueThisYear = payload.filter(task => moment(task.due_date).isSame(moment(), 'year') && !tasksObj.dueThisMonth.map((i) => i._id).includes(task._id) && !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) && !tasksObj.dueToday.map((i) => i._id).includes(task._id) && task.frequency !== 99 && task.frequency === '3');

            setTaskList(tasksObj);
            setOriginalTasksList(tasksObj);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("🚀 ~ file: Tasks.jsx:20 ~ getTasks ~ error:", error);

        }
    };

    const getFormsList = async () => {
        try {
            const { data: { payload: formsList } } = await axios.get('/api/v2/forms');
            setForms(formsList);
        } catch (error) {
            console.error("🚀 ~ file: Tasks.jsx:20 ~ getTasks ~ error:", error);
        }
    };

    const selectTask = (task) => {
        setSelectedTask(task);
        setTaskDetailOpen(true);
    };

    const isDueToday = (task) => {
        const today = new Date();
        const taskDueDate = new Date(task.due_date);
        return today.toDateString() === taskDueDate.toDateString();
    };

    const isPastDue = (task) => {
        const today = new Date();
        const taskDueDate = new Date(task.due_date);
        return taskDueDate < today.setHours(0, 0, 0, 0);
    };

    const afterSave = () => {
        getTasks();
        setSelectedTask({});
        setTaskDetailOpen(false);
    };
    return (
        <TaskContainer className="mt-3">
            <HeaderContainer className="mb-4 pb-2">
                <TaskHeader>
                    <h2>Tasks</h2>
                </TaskHeader>
                <Col lg='2' md='6' sm='12'>
                    <SearchInput value={search} onChange={(e) => handleSearch(e.target.value)} type='text' placeholder='Search' />
                </Col>
            </HeaderContainer>
            <TaskBody>
                <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <TaskListContainer>
                        <div>
                            <TaskHead>
                                <h3><span><NumberCircle>{tasksList.dueOnce.length || 0}</NumberCircle></span><TaskText>One Time Basis Tasks </TaskText></h3>
                            </TaskHead>
                            {tasksList.dueOnce.length > 0 && (
                                <TasksColumn>
                                    <TaskRowBody>
                                        {loading ?
                                            <div className='text-center my-3'>
                                                <Spinner animation="border" variant="success" />
                                            </div>
                                            :
                                            tasksList.dueOnce?.map((task) => (
                                                <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Regular Task' })} key={task._id} className="position-relative">
                                                    <TaskBadge pill>One Time Basis Task</TaskBadge>
                                                    <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                                    <TaskTitle style={{ display: "flex", alignItems: "center" }}>
                                                        {task.name}
                                                        {(isDueToday(task) || isPastDue(task)) && (
                                                            <img
                                                                style={{
                                                                    height: "16px",
                                                                    marginLeft: "5px",
                                                                    backgroundColor: isPastDue(task) ? "#FCDCDC" : "yellow",
                                                                    padding: "2px"
                                                                }}
                                                                src={warning}
                                                                alt=""
                                                                className="mr-2"
                                                            />
                                                        )}
                                                    </TaskTitle>
                                                    <TaskInstruction>
                                                        <p className="mb-5">{task.instructions}</p>
                                                        <div className="w-100" style={{
                                                            position: 'absolute',
                                                            bottom: '12px',
                                                        }}>
                                                            <div className="d-flex justify-content-between ">
                                                                <div>
                                                                    <img src={clock} alt="clock" className="mr-2" />
                                                                    {moment(task.due_date).format('MMM D')}
                                                                </div>
                                                                {task.users && task.users.includes(currentUser._id) ?
                                                                    <div>
                                                                        <NameBlocks >{currentUser.firstName?.[0]}{currentUser.lastName?.[0]}</NameBlocks>
                                                                    </div> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </TaskInstruction>
                                                </TasksCard>
                                            ))}
                                    </TaskRowBody>
                                </TasksColumn>
                            )}
                        </div>
                        <div>
                            <TaskHead>
                                <h3><span><NumberCircle>{tasksList.dueThisWeek.length || 0}</NumberCircle></span><TaskText>Weekly Tasks </TaskText></h3>
                            </TaskHead>
                            {tasksList.dueThisWeek.length > 0 && (
                                <TasksColumn>
                                    <TaskRowBody>
                                        {loading ?
                                            <div className='text-center my-3'>
                                                <Spinner animation="border" variant="success" />
                                            </div>
                                            :
                                            tasksList.dueThisWeek?.map((task) => (
                                                <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Weekly Task' })} key={task._id}
                                                    className="position-relative">
                                                    <TaskBadge style={{
                                                        background: 'rgba(239, 226, 250, 1)'
                                                    }} pill>Weekly task</TaskBadge>
                                                    <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                                    <TaskTitle style={{ display: "flex", alignItems: "center" }}>
                                                        {task.name}
                                                        {(isDueToday(task) || isPastDue(task)) && (
                                                            <img
                                                                style={{
                                                                    height: "16px",
                                                                    marginLeft: "5px",
                                                                    backgroundColor: isPastDue(task) ? "#FCDCDC" : "yellow",
                                                                    padding: "2px"
                                                                }}
                                                                src={warning}
                                                                alt=""
                                                                className="mr-2"
                                                            />
                                                        )}
                                                    </TaskTitle>
                                                    <TaskInstruction>
                                                        <p className="mb-5">{task.instructions}</p>
                                                        <div className="w-100" style={{
                                                            position: 'absolute',
                                                            bottom: '12px',
                                                        }}>
                                                            <div className="d-flex justify-content-between ">
                                                                <div>
                                                                    <img src={clock} alt="clock" className="mr-2" />
                                                                    {moment(task.due_date).format('MMM D')}
                                                                </div>
                                                                {task.users && task.users.includes(currentUser._id) ?
                                                                    <div>
                                                                        <NameBlocks >{currentUser.firstName?.[0]}{currentUser.lastName?.[0]}</NameBlocks>
                                                                    </div> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </TaskInstruction>
                                                </TasksCard>
                                            ))}
                                    </TaskRowBody>
                                </TasksColumn>
                            )}
                        </div>
                        <div>
                            <TaskHead>
                                <h3><span><NumberCircle>{tasksList.dueThisMonth.length || 0}</NumberCircle></span><TaskText>Monthly Tasks </TaskText></h3>
                            </TaskHead>
                            {tasksList.dueThisMonth.length > 0 && (
                                <TasksColumn>
                                    <TaskRowBody>
                                        {loading ?
                                            <div className='text-center my-3'>
                                                <Spinner animation="border" variant="success" />
                                            </div>
                                            :
                                            tasksList.dueThisMonth?.map((task) => (
                                                <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Monthly Task' })} key={task._id}
                                                    className="position-relative"
                                                >
                                                    <TaskBadge style={{
                                                        background: 'rgba(217, 217, 254, 1)'
                                                    }} pill>Monthly task</TaskBadge>
                                                    <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                                    <TaskTitle style={{ display: "flex", alignItems: "center" }}>
                                                        {task.name}
                                                        {(isDueToday(task) || isPastDue(task)) && (
                                                            <img
                                                                style={{
                                                                    height: "16px",
                                                                    marginLeft: "5px",
                                                                    backgroundColor: isPastDue(task) ? "#FCDCDC" : "yellow",
                                                                    padding: "2px"
                                                                }}
                                                                src={warning}
                                                                alt=""
                                                                className="mr-2"
                                                            />
                                                        )}
                                                    </TaskTitle>
                                                    <TaskInstruction>
                                                        <p className="mb-5">{task.instructions}</p>
                                                        <div className="w-100" style={{
                                                            position: 'absolute',
                                                            bottom: '12px',
                                                        }}>
                                                            <div className="d-flex justify-content-between ">
                                                                <div>
                                                                    <img src={clock} alt="clock" className="mr-2" />
                                                                    {moment(task.due_date).format('MMM D')}
                                                                </div>
                                                                {task.users && task.users.includes(currentUser._id) ?
                                                                    <div>
                                                                        <NameBlocks >{currentUser.firstName?.[0]}{currentUser.lastName?.[0]}</NameBlocks>
                                                                    </div> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </TaskInstruction>
                                                </TasksCard>
                                            ))}
                                    </TaskRowBody>
                                </TasksColumn>
                            )}
                        </div>
                        <div>
                            <TaskHead>
                                <h3><span><NumberCircle>{tasksList.quarterly.length || 0}</NumberCircle></span><TaskText>Quarterly Tasks </TaskText></h3>
                            </TaskHead>
                            {tasksList.quarterly.length > 0 && (
                                <TasksColumn>
                                    <TaskRowBody>
                                        {loading ?
                                            <div className='text-center my-3'>
                                                <Spinner animation="border" variant="success" />
                                            </div>
                                            :
                                            tasksList.quarterly?.map((task) => (
                                                <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Quarterly Task' })} key={task._id} className="position-relative">
                                                    <TaskBadge style={{
                                                        background: 'rgba(252, 220, 220, 1)'
                                                    }} pill>Quarterly task</TaskBadge>
                                                    <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                                    <TaskTitle style={{ display: "flex", alignItems: "center" }}>
                                                        {task.name}
                                                        {(isDueToday(task) || isPastDue(task)) && (
                                                            <img
                                                                style={{
                                                                    height: "16px",
                                                                    marginLeft: "5px",
                                                                    backgroundColor: isPastDue(task) ? "#FCDCDC" : "yellow",
                                                                    padding: "2px"
                                                                }}
                                                                src={warning}
                                                                alt=""
                                                                className="mr-2"
                                                            />
                                                        )}
                                                    </TaskTitle>
                                                    <TaskInstruction>
                                                        <p className="mb-5">{task.instructions}</p>
                                                        <div className="w-100" style={{
                                                            position: 'absolute',
                                                            bottom: '12px',
                                                        }}>
                                                            <div className="d-flex justify-content-between ">
                                                                <div>
                                                                    <img src={clock} alt="clock" className="mr-2" />
                                                                    {moment(task.due_date).format('MMM D')}
                                                                </div>
                                                                {task.users && task.users.includes(currentUser._id) ?
                                                                    <div>
                                                                        <NameBlocks >{currentUser.firstName?.[0]}{currentUser.lastName?.[0]}</NameBlocks>
                                                                    </div> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </TaskInstruction>
                                                </TasksCard>
                                            ))}
                                    </TaskRowBody>
                                </TasksColumn>
                            )}
                        </div>
                        <div>
                            <TaskHead>
                                <h3><span><NumberCircle>{tasksList.dueThisYear.length || 0}</NumberCircle></span><TaskText>Yearly Tasks </TaskText></h3>
                            </TaskHead>
                            {tasksList.dueThisYear.length > 0 && (
                                <TasksColumn>
                                    <TaskRowBody>
                                        {loading ?
                                            <div className='text-center my-3'>
                                                <Spinner animation="border" variant="success" />
                                            </div>
                                            :
                                            tasksList.dueThisYear?.map((task) => (
                                                <TasksCard onClick={() => selectTask({ ...task, frequencyName: 'Yearly Task' })} key={task._id}
                                                    className="position-relative">
                                                    <TaskBadge style={{
                                                        background: 'rgba(254, 254, 202, 1)'

                                                    }} pill>Yearly task</TaskBadge>
                                                    <TaskBadge pill className='ml-1'>OSHA</TaskBadge>
                                                    <TaskTitle style={{ display: "flex", alignItems: "center" }}>
                                                        {task.name}
                                                        {(isDueToday(task) || isPastDue(task)) && (
                                                            <img
                                                                style={{
                                                                    height: "16px",
                                                                    marginLeft: "5px",
                                                                    backgroundColor: isPastDue(task) ? "#FCDCDC" : "yellow",
                                                                    padding: "2px"
                                                                }}
                                                                src={warning}
                                                                alt=""
                                                                className="mr-2"
                                                            />
                                                        )}
                                                    </TaskTitle>
                                                    <TaskInstruction>
                                                        <p className="mb-5">{task.instructions}</p>
                                                        <div className="w-100" style={{
                                                            position: 'absolute',
                                                            bottom: '12px',
                                                        }}>
                                                            <div className="d-flex justify-content-between ">
                                                                <div>
                                                                    <img src={clock} alt="clock" className="mr-2" />
                                                                    {moment(task.due_date).format('MMM D')}
                                                                </div>
                                                                {task.users && task.users.includes(currentUser._id) ?
                                                                    <div>
                                                                        <NameBlocks >{currentUser.firstName?.[0]}{currentUser.lastName?.[0]}</NameBlocks>
                                                                    </div> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </TaskInstruction>
                                                </TasksCard>
                                            ))}
                                    </TaskRowBody>
                                </TasksColumn>
                            )}
                        </div>
                    </TaskListContainer>
                </div>
            </TaskBody>

            {taskDetailsOpen &&
                <TaskDetails users={users} afterSave={afterSave} forms={forms} setTaskDetailOpen={setTaskDetailOpen} open={taskDetailsOpen} task={selectedTask} />
            }
        </TaskContainer>
    )
}

export default TaskDashboard;