import { Alert, Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Swal from "sweetalert2";

import { RecordListContainer, SearchInput, TableContainer } from "./Style";
import trash from '../../assets/images/trash.svg';
import archive from '../../assets/images/archive.svg';
import openFile from '../../assets/images/open-file.svg';
import sortIcon from '../../assets/images/sort-icon.svg';
import ascendingSortIcon from '../../assets/images/descending-sort-icon.svg';
import descendingSortIcon from '../../assets/images/ascending-sort-icon.svg';
import RecordDetails from "./RecordDetails";
import { DropDownStyle } from "../../helpers";
import { DateRangeInputContainer, StatusBadge } from "../../components";


const RecordList = ({ }) => {
    const currentUser = useSelector(state => state.user.currentUser);
    const recordTypes = [{ value: 'active', label: 'Active' }, { value: 'archive', label: 'Archive' }];
    const [pendingRecord, setPendingRecord] = useState({ total: 0, list: [] });
    const [allRecord, setAllRecords] = useState({ total: 0, list: [] });
    const [filteredRecords, setFilteredRecords] = useState({ total: 0, list: [] });
    const [recordDetailsOpen, setRecordDetailOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [selectedLocation, setSelectedLocation] = useState(currentUser.location._id);
    const [forms, setForms] = useState({});
    const [recordType, setRecordType] = useState('active');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [members, setMembers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [filter, setFilter] = useState({ name: '', user: '', location: '', dateRange: { startDate: null, endDate: null } });
    const history = useHistory();
    const [orderBy, setOrderBy] = useState('completedAt');
    const [order, setOrder] = useState('asc');

    useEffect(() => {
        getMasterData();
        getPendingRecord();
    }, []);

    const getMasterData = async () => {
        try {
            setLoading(true);
            const { data: { payload: formsList } } = await axios.get('/api/v2/forms');
            setForms(formsList);
            const { data: { payload } } = await axios.get(`/api/v2/master/users_by_location/${currentUser.location._id}`);
            const { data: { payload: locations } } = await axios.get(`/api/v2/master/locations_by_user/${currentUser._id}`);
            setMembers(payload);
            setLocations(locations);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const afterUpdate = async () => {
        await getMasterData();
        await getPendingRecord();
        setRecordDetailOpen(false);
        setSelectedRecord({});
    };

    const getPendingRecord = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/pending_records/${currentUser.location._id} `);
            setPendingRecord(payload);
            getAllRecord(selectedLocation, recordType);
        } catch (error) {
            console.error("🚀 ~ getPendingRecord ~ error:", error)
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const getAllRecord = async (locationId, recordType) => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/all_records/${locationId}/${recordType}`);
            setAllRecords(payload);
            filterData(filter, payload);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
            return;
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
            setLoading(false);
        }
    };

    const selectRecord = (e, record) => {
        e.stopPropagation();
        setSelectedRecord(record);
        setRecordDetailOpen(true);
    };

    const onCloseDetailsTab = () => {
        setRecordDetailOpen(false);
        setSelectedRecord({});
    };

    const onModelChange = async (name, value) => {
        let tempFilter = { ...filter };
        if (name === 'name') {
            const keywords = value.split(',').map(keyword => keyword.trim()).filter(Boolean);
            tempFilter.name = keywords;
        } else if (name === 'dateRange') {
            tempFilter.dateRange = { startDate: new Date(value[0]).getTime() || null, endDate: new Date(value[1]).getTime() || null } || { startDate: null, endDate: null };
        } else if (name === 'location') {
            setSelectedLocation(value.value);
            await getAllRecord(value.value, recordType);
            return;
        } else if (name === 'user') {
            tempFilter.user = value ? value.value : '';
        };
        setFilter(tempFilter);
        filterData(tempFilter, allRecord);
    };

    const deleteRecord = async (e, record) => {
        e.stopPropagation();
        Swal.fire({
            icon: 'warning',
            title: "Do you want to delete the record? This process cannot be undone.",
            showDenyButton: true,
            allowEscapeKey: false,
            confirmButtonColor: '#4FB973',
            denyButtonColor: '#FF4B00',
            allowOutsideClick: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete('/api/v1/records/' + record._id);
                    getAllRecord(selectedLocation, recordType);
                    getPendingRecord();
                    Swal.fire({
                        icon: 'success',
                        title: `Record deleted successfully`,

                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    })
                } catch (error) {
                    console.error("🚀 ~ deleteRecord ~ error:", error);
                }
            };
        });
    };

    const archiveRecord = async (e, record) => {
        e.stopPropagation();
        Swal.fire({
            icon: 'question',
            title: "Please confirm you’d like to archive this record.",
            text: 'Archived Records are removed from your main activity log and only viewable in the Archived Records section. You can unarchive them at any time.',
            showDenyButton: true,
            allowEscapeKey: false,
            confirmButtonColor: '#4FB973',
            denyButtonColor: '#FF4B00',
            allowOutsideClick: false,
            confirmButtonText: "Yes",
            denyButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete('/api/v1/archive-record/' + record._id);
                    getAllRecord(selectedLocation, recordType);
                    getPendingRecord();
                    Swal.fire({
                        icon: 'success',
                        title: `Record archived successfully`,
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                } catch (error) {
                    console.error("🚀 ~ deleteRecord ~ error:", error);
                }
            };
        });
    };

    const filterData = (filterInput, data) => {
        let tempRecords = [...data.list];
        tempRecords = tempRecords.filter((rec) => {
            let res = true;
            if (filterInput.name && filterInput.name.length) {
                res = filterInput.name.some(keyword =>
                    rec.name.toLowerCase().includes(keyword.toLowerCase())
                );
            }
            if (filterInput.user && res) {
                res = rec.completedBy?._id === filterInput.user;
            };
            if (filterInput.dateRange.startDate && filterInput.dateRange.endDate && res) {
                res = (rec.completedAt >= filterInput.dateRange.startDate && rec.completedAt <= filterInput.dateRange.endDate) ? true : false;
            };
            return res;
        });
        setFilteredRecords({ total: tempRecords.length, list: tempRecords });
    };

    const sort = (colName, orderBy, stringOfNumber) => {
        let tempRecords = [...filteredRecords.list];
        setOrder(orderBy);
        setOrderBy(colName);
        if (stringOfNumber === 'string') {
            tempRecords = tempRecords.sort((a, b) => {
                const nameA = a[colName].toUpperCase(); // ignore case
                const nameB = b[colName].toUpperCase(); // ignore case

                if (orderBy === 'asc') {
                    return nameA.localeCompare(nameB); // ascending order
                } else {
                    return nameB.localeCompare(nameA); // descending order
                };
            });
        } else {
            tempRecords = tempRecords.sort((a, b) => {
                const valueA = a[colName];
                const valueB = b[colName];
                if (orderBy === 'asc') {
                    return valueA - valueB; // ascending order
                } else {
                    return valueB - valueA; // descending order
                };
            });
        }
        setFilteredRecords({ total: tempRecords.length, list: tempRecords });
    };

    return (
        <RecordListContainer>
            {pendingRecord.list.length ?
                <Row className='m-4'>
                    <TableContainer xs={12} md={12} lg={12}>
                        <p className='mt-3 heading'>Pending Records</p>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className='w-20 pl-4'>Record Name</th>
                                    <th className='w-20'>Completed By</th>
                                    <th className='w-15'>Record Created</th>
                                    <th className='w-15 text-center'>State</th>
                                    <th className='w-15 text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingRecord.list.map((pRecord) => (
                                    <tr className={selectedRecord._id === pRecord._id ? 'selected' : ""} key={"pRecord__" + pRecord._id} onClick={(e) => selectRecord(e, pRecord)}>
                                        <td className='w-20 pl-4'>{pRecord.name}</td>
                                        <td className='w-20'>{pRecord.completedBy.fullName}</td>
                                        <td className='w-15'>{moment(pRecord.startedAt).format('MM/ DD/ YYYY HH:mm') || '--'}</td>
                                        <td className='w-15'>
                                            <StatusBadge>
                                                <div className={`badge yellow`}>
                                                    <div className="dot" />
                                                    <div className="text">Pending</div>
                                                </div>
                                            </StatusBadge>
                                        </td>
                                        <td className='w-15 text-center'>
                                            <Button onClick={(e) => selectRecord(e, pRecord)} className="" variant="light"><img src={openFile} alt="Open FIle" /></Button>
                                            {currentUser.isAdmin &&
                                                <Button type="button" onClick={(e) => deleteRecord(e, pRecord)} className="mx-2" variant="light"><img src={trash} alt="Trash" /></Button>
                                            }
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </Table>
                        {pendingRecord.list.length === 0 &&
                            <Alert className='my-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                                <p className='w-100 mb-0 text-center'>No pending records.</p>
                            </Alert>
                        }
                    </TableContainer>
                </Row>
                :
                null
            }
            <Row className='m-4'>
                <TableContainer xs={12} md={12} lg={12}>
                    <Row style={{ 'borderBottom': "2px solid #dddddd" }} className="px-0 mx-1 my-2 justify-content-between">
                        <Col lg="2" md="4" sm="6" className="px-0"> <p className='heading'>All Records</p></Col>
                        <Col lg="2" md="4" sm="6" className="px-0">
                            <Button type="button" onClick={() => history.push('/forms')} size="lg" variant="success" className="w-100">+ New Record</Button>
                        </Col>
                    </Row>
                    <Row className="px-0 mb-2">
                        <Col lg="2" md="4" sm="6">
                            <SearchInput placeholder="Search" onChange={(e) => onModelChange('name', e.target.value)} />
                        </Col>
                        <Col lg="2" md="4" sm="6">
                            <Select
                                name='user'
                                placeholder="Completed By"
                                options={members}
                                onChange={(e) => onModelChange('user', e)}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                isClearable={true}
                            />
                        </Col>
                        <Col lg="2" md="4" sm="6">
                            <Select
                                name='location'
                                placeholder="Practice Location"
                                options={locations}
                                onChange={(e) => onModelChange('location', e)}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                value={locations.find(loc => loc.value === selectedLocation)}
                            />
                        </Col>
                        <DateRangeInputContainer lg="2" md="4" sm="6">
                            <DatePicker
                                placeholderText='Date Range'
                                selectsRange={true}
                                startDate={filter.dateRange.startDate}
                                endDate={filter.dateRange.endDate}
                                onChange={(dates) => onModelChange('dateRange', dates)}
                                className="date-input"
                                isClearable={true}
                            />
                        </DateRangeInputContainer>
                        <Col lg="2" md="4" sm="6">
                            <Select
                                name='location'
                                placeholder="Record Type"
                                options={recordTypes}
                                onChange={(e) => { setRecordType(e.value); getAllRecord(selectedLocation, e.value) }}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                value={recordTypes.find(loc => loc.value === recordType)}
                            />
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th className='w-20 pl-4'>Record Name   <span>
                                    {(orderBy !== 'name') && <img onClick={() => sort('name', 'asc', 'string')} className='cursor-pointer' src={sortIcon} title="Apply Sort Icon" />}
                                    {(orderBy === 'name' && order === 'asc') && <img onClick={() => sort('name', 'desc', 'string')} className='cursor-pointer' src={ascendingSortIcon} title="Ascending Sort Icon" />}
                                    {(orderBy === 'name' && order === 'desc') && <img onClick={() => sort('name', 'asc', 'string')} className='cursor-pointer' src={descendingSortIcon} title="Descending Sort Icon" />}
                                </span></th>
                                <th className='w-20'>Completed By   <span>
                                    {(orderBy !== 'completedBy1') && <img onClick={() => sort('completedBy1', 'asc', 'string')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                    {(orderBy === 'completedBy1' && order === 'asc') && <img onClick={() => sort('completedBy1', 'desc', 'string')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                    {(orderBy === 'completedBy1' && order === 'desc') && <img onClick={() => sort('completedBy1', 'asc', 'string')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                </span></th>
                                <th className='w-15'>Record Created   <span>
                                    {(orderBy !== 'startedAt') && <img onClick={() => sort('startedAt', 'asc', 'number')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                    {(orderBy === 'startedAt' && order === 'asc') && <img onClick={() => sort('startedAt', 'desc', 'number')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                    {(orderBy === 'startedAt' && order === 'desc') && <img onClick={() => sort('startedAt', 'asc', 'number')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                </span></th>
                                <th className='w-15 text-center'>State </th>
                                <th className='w-15 text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading ? filteredRecords.list.map((record) => (
                                <tr className={selectedRecord._id === record._id ? 'selected' : ""} key={"record__" + record._id} onClick={(e) => selectRecord(e, record)}>
                                    <td className='w-20 pl-4'>{record.name}</td>
                                    <td className='w-20'>{record.completedBy.fullName}</td>
                                    <td className='w-15'>{moment(record.startedAt).format('MM/DD/YYYY hh:mm A') || '--'}</td>
                                    <td className='w-15'>
                                        <StatusBadge>
                                            <div className={`badge green`}>
                                                <div className="dot" />
                                                <div className="text">Approved</div>
                                            </div>
                                        </StatusBadge>
                                    </td>
                                    <td className='w-15 text-center'>
                                        <Button onClick={(e) => selectRecord(e, record)} className="" variant="light"><img src={openFile} alt="Open FIle" /></Button>
                                        {recordType === 'active' && currentUser.isAdmin &&
                                            <>
                                                <Button type="button" onClick={(e) => deleteRecord(e, record)} className="mx-2" variant="light"> <img src={trash} alt="Trash" /></Button>
                                                <Button onClick={(e) => archiveRecord(e, record)} variant="light"> <img src={archive} alt="Archive" /></Button>
                                            </>
                                        }
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={6}>
                                        <div className='w-100 text-center'>
                                            <Spinner animation="border" variant="success" />
                                        </div>
                                    </td>
                                </tr>}
                        </tbody>
                    </Table>
                    {filteredRecords.list.length === 0 &&
                        <Alert className='my-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                            <p className='w-100 mb-0 text-center'>No records.</p>
                        </Alert>
                    }
                </TableContainer>
            </Row>
            {recordDetailsOpen && <RecordDetails recordType={recordType} archiveRecord={archiveRecord} deleteRecord={deleteRecord} onCloseDetailsTab={onCloseDetailsTab} afterUpdate={afterUpdate} members={members} forms={forms} setRecordDetailOpen={setRecordDetailOpen} record={selectedRecord} />}
        </RecordListContainer>
    )
}

export default RecordList;