import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';

const ProgressBardContainer = styled(Row)`
    align-items: center;
`;
const CircularProgressbarContainer = styled(Col)`
    
`;

const TextContainer = styled(Col)`
    align-items: center;
`;

const TrainingProgressBar = ({ trainingData }) => {
    const trainingDeadline = trainingData.trainingMinutesResetAt ? moment(trainingData.trainingMinutesResetAt).add(1, 'year').format('MMMM D, YYYY') :
        moment(trainingData.trainingDueDate).format('MMMM D, YYYY');
    let trainingMinutesWatched = trainingData.trainingMinutesWatched || 0;
    trainingMinutesWatched = trainingMinutesWatched / 60;
    let trainingPercentage = ((trainingMinutesWatched / 60) * 100).toFixed(2);
    trainingPercentage = trainingPercentage > 100 ? 100 : trainingPercentage;
    return (
        <ProgressBardContainer>
            <CircularProgressbarContainer lg={2} md={4} sm={2}>
                <CircularProgressbar value={trainingPercentage} text={trainingPercentage + '%'} styles={{
                    path: { stroke: `#4fb973`, strokeLinecap: 'butt' },
                    text: { fill: '#4fb973', fontSize: '20px', fontWeight: 'bold' }
                }} />
            </CircularProgressbarContainer>
            <TextContainer className='ml-2' lg={9} md={7} sm={12}>
                <h2>
                    {trainingMinutesWatched > 60 ? 60 : trainingMinutesWatched.toFixed(2)} Min <span>/ 60</span>
                </h2>
                <p className='p-0 m-0'>
                    You must watch at least 60 minutes of training by {trainingDeadline}
                </p>
            </TextContainer>
        </ProgressBardContainer>
    )
}

export default TrainingProgressBar;