import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { AddEditLocation, Input, Select } from '../../components';

import addIcon from '../../assets/images/add.svg';
import pencil from '../../assets/images/pencil.svg';
import sortIcon from '../../assets/images/sort-icon.svg';
import ascendingSortIcon from '../../assets/images/descending-sort-icon.svg';
import descendingSortIcon from '../../assets/images/ascending-sort-icon.svg';
import applyFilterGray from '../../assets/images/filter-apply.svg';
import applyFilterGreen from '../../assets/images/filter-active.svg';

import { AddButton, ButtonContainer, CrosseArrowButton, FilterContainer, OverlayContainer, PaginationSection, TableContainer, TableHead } from './Style';
import { states } from '../../helpers';
import { loadUser } from '../../actions';

const LocationContainer = styled.div`

`;

class LocationList extends Component {
    state = {
        loading: false,
        locations: [],
        locationDetails: { name: '' },
        orderBy: 'nameForSort',
        order: 'asc',
        selectedLocations: [],
        pageSize: 10,
        pageNumber: 1,
        pageCount: 0,
        totalCount: 0,
        addOrEditLocation: false,
        errors: {
            maxLocations: false,
        },
        locationLoading: false,
        saveOrUpdateInProgress: false,
        isDeleteLocationModalOpen: false,
        editLocationModalOpen: false,
        modalData: null,
        filterDetails: {},
        activeFilterDetails: {},
        filterColumns: {}
    };

    componentDidMount() {
        this.getLocations();
    };

    componentDidUpdate(prevProps, prevState) {

    };

    getLocations = async () => {
        try {
            const { user } = this.props;
            const { pageSize, pageNumber, orderBy, order, activeFilterDetails } = this.state;
            this.setState({ loading: true });
            const { data: { payload } } = await axios.get(`/api/v2/locations/${user._id}`, { params: { ...activeFilterDetails, pageSize, pageNumber, orderBy, order } });
            const pageCount = Math.ceil(payload.totalCount / pageSize);
            this.setState({ loading: false, locations: payload.data, totalCount: payload.totalCount, pageCount, modalData: payload.data?.[0]?.annual_review_date });
        } catch (error) {
            this.setState({ loading: false });
            console.error(error);
        };
    };

    sort = (orderBy, order) => {
        this.setState({ order, orderBy, pageNumber: 1, pageSize: 10 }, () => {
            this.getLocations();
        });
    };

    handlePageClick = (val) => {
        this.setState({ pageNumber: val.selected + 1 }, () => {
            this.getLocations();
        });
    };

    viewRole = (locationId) => {
        const { history } = this.props;
        history.push('/admin/location-view?locationId=' + locationId);
    };

    addEditLocation = (locationDetails) => {
        this.setState({ editLocationModalOpen: true, locationDetails, modalData: this.state.modalData });
    };

    closePopUpModalDialog = (getLocations) => {
        this.setState({ editLocationModalOpen: false, locationDetails: {}, modalData: null });
        if (getLocations) {
            this.getLocations();
        };
    };

    onFilterInputChange = (name, value) => {
        const { filterDetails } = this.state;
        filterDetails[name] = value;
        this.setState({ filterDetails });
    };

    applyFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;
        if (colName === 'trainingDueDate') {
            activeFilterDetails[colName] = moment(filterDetails[colName]).subtract(1, 'year');
        } else {
            activeFilterDetails[colName] = filterDetails[colName];
        };
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getLocations();
        });
    };

    clearFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;

        delete filterDetails[colName];
        delete activeFilterDetails[colName];
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getLocations();
        });
    };

    openCloseFilterSection = (colName) => {
        const { filterColumns } = this.state;
        filterColumns[colName] = !filterColumns[colName];
        this.setState({
            filterColumns
        });
    };

    afterLocationUpdateOrAdd = async () => {
        const { dispatch, user } = this.props;
        await dispatch(loadUser(user));
    };

    render() {
        const { locations, loading, pageNumber, pageCount, orderBy, order, editLocationModalOpen, locationDetails, filterDetails, filterColumns, activeFilterDetails } = this.state;
        const { user, stripe, practice } = this.props;
        const types = { medical: 'Medical', dental: 'Dental', Veterinary: 'Veterinary', medicalLaboratory: 'Medical Laboratory' };
        const typesArray = [{ label: 'Medical', value: 'medical' }, { label: 'Dental', value: 'dental' }];
        return (
            <LocationContainer>
                <div className="d-flex justify-content-end mx-2 mb-3 mt-2">
                    <div>
                        <AddButton width='150' onClick={() => this.addEditLocation({})} type='button' className='my-0' ><img src={addIcon} alt="Add Icon" /> Add Location</AddButton>
                    </div>
                </div>
                <TableContainer maxHeight='60vh'>
                    <table className='table'>
                        <TableHead>
                            <tr>
                                <th className='w-20 pl-4'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.name && !activeFilterDetails.name) && <img onClick={() => this.openCloseFilterSection('name')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.name || activeFilterDetails.name) && <img onClick={() => this.openCloseFilterSection('name')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.name &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.name || ''} onChange={(e) => this.onFilterInputChange('name', e.target.value)} placeholder='Name' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('name')}>Clear</button>
                                                    <button disabled={!filterDetails.name} className='btn btn-success' type='button' onClick={() => this.applyFilter('name')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Location Name
                                    <span>
                                        {(orderBy !== 'nameForSort') && <img onClick={() => this.sort('nameForSort', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'nameForSort' && order === 'asc') && <img onClick={() => this.sort('nameForSort', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'nameForSort' && order === 'desc') && <img onClick={() => this.sort('nameForSort', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-15'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.type && !activeFilterDetails.type) && <img onClick={() => this.openCloseFilterSection('type')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.type || activeFilterDetails.type) && <img onClick={() => this.openCloseFilterSection('type')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.type &&
                                            <OverlayContainer>
                                                <Select defaultValue={typesArray.find((type) => type.value === activeFilterDetails.type)} handleDropdownChange={(e) => this.onFilterInputChange('type', e.target.value)} options={typesArray} placeholder='Type' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('type')}>Clear</button>
                                                    <button disabled={!filterDetails.type} className='btn btn-success' type='button' onClick={() => this.applyFilter('type')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Location Type <span>
                                        {(orderBy !== 'type') && <img onClick={() => this.sort('type', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'type' && order === 'asc') && <img onClick={() => this.sort('type', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'type' && order === 'desc') && <img onClick={() => this.sort('type', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-20'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.address && !activeFilterDetails.address) && <img onClick={() => this.openCloseFilterSection('address')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.address || activeFilterDetails.address) && <img onClick={() => this.openCloseFilterSection('address')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.address &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.address || ''} onChange={(e) => this.onFilterInputChange('address', e.target.value)} placeholder='Address 1' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('address')}>Clear</button>
                                                    <button disabled={!filterDetails.address} className='btn btn-success' type='button' onClick={() => this.applyFilter('address')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Address <span>
                                        {(orderBy !== 'address') && <img onClick={() => this.sort('address', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'address' && order === 'asc') && <img onClick={() => this.sort('address', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'address' && order === 'desc') && <img onClick={() => this.sort('address', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-10'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.city && !activeFilterDetails.city) && <img onClick={() => this.openCloseFilterSection('city')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.city || activeFilterDetails.city) && <img onClick={() => this.openCloseFilterSection('city')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.city &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.city || ''} onChange={(e) => this.onFilterInputChange('city', e.target.value)} placeholder='City' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('city')}>Clear</button>
                                                    <button disabled={!filterDetails.city} className='btn btn-success' type='button' onClick={() => this.applyFilter('city')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    City <span>
                                        {(orderBy !== 'city') && <img onClick={() => this.sort('city', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'city' && order === 'asc') && <img onClick={() => this.sort('city', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'city' && order === 'desc') && <img onClick={() => this.sort('city', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-10'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.state && !activeFilterDetails.state) && <img onClick={() => this.openCloseFilterSection('state')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.state || activeFilterDetails.state) && <img onClick={() => this.openCloseFilterSection('state')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.state &&
                                            <OverlayContainer>
                                                <Select defaultValue={states.find((state) => state.value === activeFilterDetails.state)} handleDropdownChange={(e) => this.onFilterInputChange('state', e.target.value)} options={states} placeholder='State' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('state')}>Clear</button>
                                                    <button disabled={!filterDetails.state} className='btn btn-success' type='button' onClick={() => this.applyFilter('state')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    State <span>
                                        {(orderBy !== 'state') && <img onClick={() => this.sort('state', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'state' && order === 'asc') && <img onClick={() => this.sort('state', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'state' && order === 'desc') && <img onClick={() => this.sort('state', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-15'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.zip && !activeFilterDetails.zip) && <img onClick={() => this.openCloseFilterSection('zip')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.zip || activeFilterDetails.zip) && <img onClick={() => this.openCloseFilterSection('zip')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.zip &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.zip || ''} onChange={(e) => this.onFilterInputChange('zip', e.target.value)} placeholder='Postal Code' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('zip')}>Clear</button>
                                                    <button disabled={!filterDetails.zip} className='btn btn-success' type='button' onClick={() => this.applyFilter('zip')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Postal Code <span>
                                        {(orderBy !== 'zip') && <img onClick={() => this.sort('zip', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'zip' && order === 'asc') && <img onClick={() => this.sort('zip', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'zip' && order === 'desc') && <img onClick={() => this.sort('zip', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='text-center w-10'>Actions</th>
                            </tr>
                        </TableHead>
                        {!loading &&
                            <tbody>
                                {locations.length && locations.map((location) => (
                                    <tr key={location._id}>
                                        <td className='cursor-pointer w-20 pl-4' onClick={() => this.viewRole(location._id)}><CrosseArrowButton>{location.name}</CrosseArrowButton></td>
                                        <td className='w-15'>{types[location.type]} <b>( {location.locSubscriptionPlan} )</b></td>
                                        <td className='w-20'>{location.address ? location.address : '--'}</td>
                                        <td className='w-10'>{location.city}</td>
                                        <td className='w-10'>{location.state}</td>
                                        <td className='w-15'>{location.zip}</td>
                                        <td className='text-center w-10'>
                                            <img onClick={() => this.addEditLocation(location)} src={pencil} className='mr-2 cursor-pointer' alt="Edit" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                    {
                        loading &&
                        <div className='w-100 text-center'>
                            <Spinner animation="border" variant="success" />
                        </div>
                    }
                </TableContainer>

                <PaginationSection>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        previousLabel="<"
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                    />
                </PaginationSection>
                {editLocationModalOpen &&
                    <AddEditLocation practice={practice} modalData={this.state.modalData} stripe={stripe} location={locationDetails._id} show={editLocationModalOpen}
                        closePopUpModal={this.closePopUpModalDialog} user={user} afterLocationUpdateOrAdd={this.afterLocationUpdateOrAdd} />}
            </LocationContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    stripe: state.admin.stripe,
    practice: state.user.currentUser.practice || {},
});

export default connect(mapStateToProps)(LocationList);